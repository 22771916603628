import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  InputBase,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useNavigate } from "react-router-dom";

const SettingCard = ({ icon: Icon, title, description, link }) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        position: "relative",
        overflow: "visible",
        borderRadius: "8px",
        minHeight: "150px",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        "&:hover": {
          boxShadow: "0 0 15px rgba(0,0,0,0.2)",
        },
        cursor: "pointer",
      }}
      onClick={() => navigate(link)}
    >
      <CardContent sx={{ p: 3, "&:last-child": { pb: 3 } }}>
        <Box
          sx={{
            position: "absolute",
            top: 30,
            left: 20,
            width: 60,
            height: 60,
            borderRadius: "8px",
            backgroundColor: "#5e50ee",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0 0 10px rgba(94,80,238,0.5)",
          }}
        >
          <Icon sx={{ fontSize: 32, color: "white" }} />
        </Box>
        <Box sx={{ ml: 10, mt: 1 }}>
          <Typography
            variant="h6"
            component="h3"
            sx={{ fontWeight: "bold", mb: 1 }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ lineHeight: 1.2 }}
          >
            {description}
          </Typography>
        </Box>
      </CardContent>
      <Box
        sx={{
          position: "absolute",
          bottom: -10,
          right: -10,
          opacity: 0.1,
        }}
      >
        <Icon sx={{ fontSize: 80, color: "#5e50ee" }} />
      </Box>
    </Card>
  );
};

const SystemSetting = () => {
  return (
    <div>
      <Box sx={{ bgcolor: "#f3f3f9", p: 3 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 6 }}
        >
          System Settings
        </Typography>

        {/* <Card sx={{ mb: 3 }}>
              <CardContent sx={{ p: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', borderRadius: 1 }}>
                  <IconButton sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search..."
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </Box>
              </CardContent>
            </Card> */}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <SettingCard
              icon={SettingsOutlinedIcon}
              title="Update Setting"
              description="Update transaction settings, including UPI/TRX address, deposit bonus commission, and commission rates."
              link="/settings-admin"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SettingCard
              icon={AccountBalanceOutlinedIcon}
              title="Withdrawal Setting"
              description="Adjust the minimum and maximum withdrawal limits for user transactions"
              link="/withdrawl-limits"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SettingCard
              icon={DateRangeIcon}
              title="Activity Setting"
              description="Configure rewards for user activities, including points, bonuses, and achievement criteria."
              link="/admin/activity-award"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <SettingCard
              icon={TrackChangesIcon}
              title="Lucky Spin"
              description="Manage and customize admin tasks related to Lucky Spin activities and rewards."
              link="/lucky-spin"
            />
          </Grid> */}
          <Grid item xs={12} sm={6} md={4}>
            <SettingCard
              icon={CreditCardOutlinedIcon}
              title="Invitation Bonus"
              description="View and manage insights, stats, and settings for the Invitation Bonus program."
              link="/admin/invitation-bonus"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SettingCard
              icon={RequestQuoteOutlinedIcon}
              title="First Deposit Bonus"
              description="Set criteria and manage rewards for the First Deposit Bonus program."
              link="/bonus-settings"
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SystemSetting;
